header {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  min-height: 10vh;

  a {
    color: black;
    text-decoration: none;
    margin-right: 24px;
  }
}